<template scoped>
  <div v-if="loadSpinner" class="spinner-wrapper">
    <div class="spinner-card">
      <svg
        class="spinner"
        width="70px"
        height="60px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          class="path"
          fill="none"
          stroke-width="6"
          stroke-linecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
      <br />
      <h5 v-if="isSchedulePickup">Please wait...</h5>
      <h5 v-else>Downloading...</h5>
      <!-- <p>Please wait we are cooking something for you</p> -->
    </div>
  </div>
</template>
<script>
export default {
  props: ["loadSpinner", "isSchedulePickup"],
  methods: {}
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner-wrapper {
  position: fixed;
  left: -6px;
  top: 0;
  height: 100%;
  width: 100%;
  background: #1313136c;
  z-index: 2000;
}
.spinner-card {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #00000050;
  width: 400px;
  height: 200px;
}
.spinner-card h5 {
  position: absolute;
  top: 130px;
  left: 34%;
  color: #190f27;
}
.spinner-card p {
  position: absolute;
  top: 165px;
  left: 55px;
  color: #473f52;
}
.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
  animation: rotator 1.4s linear infinite;
  position: absolute;
  left: 42%;
  top: 35px;
  z-index: 1100;
}

@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite,
    colors 5.6s ease-in-out infinite;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@-webkit-keyframes colors {
  0% {
    stroke: #5658d1;
  }
  25% {
    stroke: #5658d1;
  }
  50% {
    stroke: #5658d1;
  }
  75% {
    stroke: #5658d1;
  }
  100% {
    stroke: #5658d1;
  }
}

@keyframes colors {
  0% {
    stroke: #5658d1;
  }
  25% {
    stroke: #5658d1;
  }
  50% {
    stroke: #5658d1;
  }
  75% {
    stroke: #5658d1;
  }
  100% {
    stroke: #5658d1;
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}
</style>

